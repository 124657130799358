import { AgentSelector } from '@/aiAssistant/AgentSelector.atom';
import { MarkdownSnippet } from '@/aiAssistant/MarkdownSnippet.molecule';
import {
  Agent,
  agents,
  AgentType,
  assistantLocations,
  AssistantOrigin,
  ChatMessage,
} from '@/aiAssistant/aiAssistant.types';
import { Button, Icon } from '@seeqdev/qomponents';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Feedback } from '@/aiAssistant/feedback/Feedback.atom';
import { StatusUpdate } from '@/aiAssistant/StatusUpdates.atom';
import { CopyButtonGivenText } from '@/core/CopyButtonGivenText.atom';
import { sqAiAssistantStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { INVESTIGATE_TOOLS } from '@/toolSelection/investigate.constants';
import { setActiveTool } from '@/toolSelection/investigate.actions';
import TextAreaAutosize from 'react-textarea-autosize';
import { copyToClipboard } from './aiAssistant.utiilities';
import { successToast } from '@/utilities/toast.utilities';

interface MessageProps {
  message: ChatMessage;
  insertFormulaSnippet: (snippet: string) => void;
  last?: boolean;
  isRunning: boolean;
  selectedAgent?: AgentType;
  onAgentChange?: (agent: AgentType) => void;
  submitPrompt: (prompt: string, origin: AssistantOrigin, context?: Record<string, unknown>) => void;
  origin: AssistantOrigin;
  scrollToBottom: () => void;
  isHidingAnswer?: boolean;
  toggleAnswerVisibility: () => void;
  onQuestionChange: (question: string) => void;
}

const getStyleByOrigin = (origin: AssistantOrigin) => {
  switch (origin) {
    case 'workbench':
      return 'analysis';
    case 'datalab':
      return 'datalab';
    default:
      return 'seeq-blue';
  }
};

const getOriginLabel = (message: ChatMessage) => {
  if (message.origin) {
    return `${assistantLocations[message.origin]} ${message.originDetails?.label ?? ''}`;
  }
  return '';
};

const Question: React.FunctionComponent<{
  message: ChatMessage;
  toggleAnswerVisibility: () => void;
  isHidingAnswer: boolean | undefined;
  allowHidingAnswer?: boolean;
  onQuestionChange: (question: string) => void;
  isRunning: boolean;
}> = ({ message, toggleAnswerVisibility, isHidingAnswer, allowHidingAnswer, isRunning, onQuestionChange }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = React.useState(false);

  return (
    <div key={message.id} data-testid="message" className={`flexRowContainer pt15 pr15 pl15 pb5 ${message.role}`}>
      <div className="flexColumnContainer">
        <Icon icon="fa-user" testId="fa-user" extraClassNames="pl5 pt3 pr10" />
        <div className="flexFill messageWithTags">
          <div className="flexFill messageContent mr10">
            {!isEditing && (
              <div
                className={`questionText ${isRunning ? '' : 'editable'}`}
                onClick={() => !isRunning && setIsEditing(true)}>
                <MarkdownSnippet id={message.id} markdown={message.dialog} insertFormulaSnippet={_.noop} />
                <Icon icon="fa-pencil" extraClassNames="ml5 mt3" />
              </div>
            )}
            {isEditing && (
              <TextAreaAutosize
                autoFocus
                className="w-100 mb2"
                defaultValue={message.dialog}
                maxRows={7}
                minRows={1}
                onBlur={() => setIsEditing(false)}
                onKeyDown={({ key, target }) => {
                  if (['Enter', 'Escape'].includes(key)) setIsEditing(false);
                  if (key === 'Enter') {
                    const value = (target as HTMLTextAreaElement).value;
                    if (value && value !== message.dialog) onQuestionChange(value);
                  }
                }}
                onFocus={({ target }) => target.setSelectionRange(message.dialog.length, message.dialog.length)}
              />
            )}
          </div>

          {message.origin && (
            <div className="flexColumnContainer flexAlignCenter flexFill">
              <div className="flexFill" />
              <div className={`tags ${getStyleByOrigin(message.origin)}`}>{getOriginLabel(message)}</div>
            </div>
          )}
        </div>
        {allowHidingAnswer && (
          <Button
            variant="no-border"
            extraClassNames="flexRowContainer flexAlignCenter pt0"
            onClick={toggleAnswerVisibility}
            icon={isHidingAnswer ? 'fa-chevron-down' : 'fa-chevron-up'}
            tooltip={isHidingAnswer ? t('AI_ASSISTANT.SHOW_ANSWER') : t('AI_ASSISTANT.HIDE_ANSWER')}
          />
        )}
        <CopyButtonGivenText
          textToCopy={message.dialog}
          extraClassNames="flexRowContainer mt2"
          tooltip="AI_ASSISTANT.COPY_QUESTION.TO_CLIPBOARD"
          notifyMessage="AI_ASSISTANT.COPY_QUESTION.SUCCESS"
        />
      </div>
    </div>
  );
};

const ToolButtons: React.FunctionComponent<{ components: string[] | undefined }> = ({ components }) => {
  const { t } = useTranslation();
  const tools =
    _.isArray(components) &&
    components?.map((component) => INVESTIGATE_TOOLS.find((tool) => tool.id === component)).filter((tool) => !!tool);

  if (!tools || tools.length === 0) return null;

  return (
    <div className="flexColumnContainer flexAlignCenter flexWrap gap5">
      {t('AI_ASSISTANT.DO_MORE')}:
      {tools.slice(0, 3).map((tool) => (
        <Button
          key={tool?.id}
          label={t(tool?.nameTranslationKey as any)}
          variant="outline"
          icon="fa-arrow-up-right-from-square"
          iconColor="#0f7961"
          iconStyle="color"
          onClick={() => setActiveTool(tool?.id as string)}
          extraClassNames="text-nowrap shareButton"
        />
      ))}
    </div>
  );
};

export const Message: React.FunctionComponent<MessageProps> = ({
  isRunning,
  message,
  insertFormulaSnippet,
  last,
  selectedAgent,
  onAgentChange,
  origin,
  submitPrompt,
  scrollToBottom,
  toggleAnswerVisibility,
  isHidingAnswer,
  onQuestionChange,
}) => {
  const [agent, setAgent] = React.useState<Agent>(_.find(agents, { key: selectedAgent })!);
  const { t } = useTranslation();

  const messages = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.messages);
  // TEMPORARY: For right now we don't get an agent returned from the server - so fake it until you make it!
  if (!agent && !selectedAgent) {
    const fakeAgent = _.sample(agents);
    message.agent = fakeAgent!.key;
    setAgent(fakeAgent!);
  }

  return message.role === 'user' ? (
    <Question
      message={message}
      toggleAnswerVisibility={toggleAnswerVisibility}
      isHidingAnswer={isHidingAnswer}
      allowHidingAnswer
      onQuestionChange={onQuestionChange}
      isRunning={isRunning}
    />
  ) : (
    <div key={message.id} data-testid="message" className={`flexRowContainer pt15 pr15 pl15 pb10 ${message.role}`}>
      <div className="flexColumnContainer flexAlignCenter flexSpaceBetween mb8">
        <div
          className="flexColumnContainer flexAlignCenter"
          data-qtip-is-html="true"
          data-qtip-text={`${t('AI_ASSISTANT.AGENT_INFO', { agent: agent?.name })}<br/>${t(
            'AI_ASSISTANT.AGENT_SWITCH',
          )}`}>
          <Icon
            icon={agent?.icon || 'fa-sparkles'}
            testId="fc-genai-chat"
            extraClassNames="pl5 pr2"
            type="color"
            color={agent?.color}
          />
          {onAgentChange ? (
            <AgentSelector
              onAgentChange={onAgentChange}
              showAgentName={true}
              selectedAgentId={selectedAgent}
              showSelected={false}
              isRunning={isRunning}
            />
          ) : (
            <div className="pl5">{agent?.name}</div>
          )}
        </div>
        <div className="flexColumnContainer flexAlignCenter gap10">
          <StatusUpdate
            stages={message.stages}
            isRunning={isRunning}
            isLast={last}
            isResponding={!_.isEmpty(message.dialog)}
          />
          <Button
            tooltip={t('AI_ASSISTANT.COPY_ANSWER.TO_CLIPBOARD')}
            icon="fa-copy"
            iconStyle="theme"
            variant="no-border"
            extraClassNames="flexSelfEnd p0 ai-button"
            onClick={() =>
              copyToClipboard(message.id, () => {
                successToast({ messageKey: 'AI_ASSISTANT.COPY_ANSWER.SUCCESS' });
              })
            }
          />
        </div>
      </div>

      <div className="flexRowContainer flexFill ml26">
        <MarkdownSnippet id={message.id} markdown={message.dialog} insertFormulaSnippet={insertFormulaSnippet} />
        <ToolButtons components={message.components} />
        <div>
          {isRunning && last && (
            <Icon icon="fa-circle fa-beat" iconPrefix="fa-solid" extraClassNames="ml5" type="dark-gray" small={true} />
          )}
          {!isRunning && last && (
            <Feedback
              onSubmitPrompt={submitPrompt}
              origin={origin}
              scrollToBottom={scrollToBottom}
              trackingContext={messages}
            />
          )}
        </div>
      </div>
    </div>
  );
};
