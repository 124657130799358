import React, { useEffect, useRef, useState } from 'react';
import { AiAssistant } from '@/aiAssistant/AiAssistant.organism';
import {
  Actions,
  BorderNode,
  I18nLabel,
  IJsonModel,
  IJsonTabNode,
  IJsonTabSetNode,
  ITabSetRenderValues,
  Layout,
  Model,
  TabNode,
  TabSetNode,
} from 'flexlayout-react';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqAiAssistantStore } from '@/core/core.stores';
import { Icon } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { Rnd } from 'react-rnd';
import { setAIAssistantDisplayed, setAIAssistantMaximized } from '@/aiAssistant/aiAssistant.actions';
import { doTrack } from '@/track/track.service';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { useLocation } from 'react-router-dom';
import { getWorkbookDisplayMode, isAIStandalone } from '@/main/routing.utilities';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';

interface ResizableWindowWrapperProps {
  children?: React.ReactNode;
}

const workbenchTabSetId = 'workbenchTabSet';
const assistantTabSetId = 'assistantTabSet';
const workbenchTabId = 'main';
const assistantTabId = 'aiTab';

const assistantTab: IJsonTabNode = {
  type: 'tab',
  name: 'AI Assistant',
  component: assistantTabId,
  id: assistantTabId,
};

const assistantTabSet: IJsonTabSetNode = {
  type: TabSetNode.TYPE,
  headerHeight: 30,
  enableTabStrip: false,
  id: assistantTabSetId,
  name: 'AI Assistant',
  weight: 25,
  minWidth: 450,
  enableClose: true,
  children: [assistantTab],
};

const json: IJsonModel = {
  global: {
    tabSetEnableSingleTabStretch: true,
    tabEnableRenderOnDemand: false,
    splitterSize: 3,
  },
  borders: [],
  layout: {
    id: 'root',
    type: 'row',
    weight: 100,
    children: [
      {
        type: TabSetNode.TYPE,
        id: workbenchTabSetId,
        enableClose: false,
        enableDrop: false,
        enableTabStrip: false,
        tabStripHeight: 0,
        weight: 75,
        children: [
          {
            type: 'tab',
            name: 'Seeq',
            component: workbenchTabId,
          },
        ],
      },
      assistantTabSet,
    ],
  },
};

/**
 * This component is the Wrapper component that facilitates the resizing and docking of the AI Assistant
 * It is built on top of https://github.com/caplin/FlexLayout
 *
 * FlexLayout is based on the notion of TabSets and Tabs.
 * TabSets are the containers for Tabs and can be resized and docked; Tabs on the other hand are simply the content
 * that is displayed within the TabSet. A TabSet can contain multiple Tabs but for our current usecase we limit a
 * TabSet to one Tab.
 *
 * The differentiation between Tab and TabSet is important as the Action of resizing and docking can only be applied
 * to a TabSet - not a Tab!
 *
 * Removing a TabSet is supported however there is no support for programmatically creating a new TabSet - which
 * means that to "remove" the AI Assistant from the TabSet we actually "maximize" the workbench TabSet.
 *
 * To "add" the AI Assistant back to the TabSet we "restore" the workbench TabSet. The same applies to "popping out"
 * into a modal - we don't delete the TabSet, we simply "maximize" the workbench TabSet and display the AI Assistant
 * in a react-rnd modal.
 *
 * The workbench TabSet does not have a TabStrip (the "header") and is not closable. It also does not support
 * dropping - this is so that we can not end up in a scenario where the AI Assistant is dropped into the workbench
 * TabSet and seemingly vanishes workbench (as there are no Tab Headers displayed in the workbench TabSet).
 *
 * The resizable and draggable modal is based on https://github.com/bokuweb/react-rnd; the implementation is pretty
 * straight forward and there were no got-cha's to be aware of.
 **/
export const ResizableWindowWrapper: React.FunctionComponent<ResizableWindowWrapperProps> = ({ children }) => {
  const aiAssistantDisplayed = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.displayed);
  const [layoutModel, setLayoutModel] = useState<Model>();
  const assistantRef = useRef<HTMLDivElement>(null);
  const layoutRef = useRef<Layout>(null);
  const [historyShown, setHistoryShown] = useState(false);
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const aiDisplayedRef = useRef<boolean>();
  const aiStandalone = isAIStandalone();
  useLocation();
  const isPresentationMode = getWorkbookDisplayMode() === WORKBOOK_DISPLAY.PRESENT;

  useEffect(() => {
    if (!layoutModel) {
      const model = Model.fromJson(json);
      if (!aiAssistantDisplayed) {
        model.doAction(Actions.maximizeToggle(workbenchTabSetId));
      }
      aiDisplayedRef.current = aiAssistantDisplayed;
      setLayoutModel(model);
    } else {
      if (displayModal) {
        setDisplayModal(false);
        aiDisplayedRef.current = false;
      } else if (aiAssistantDisplayed !== aiDisplayedRef.current) {
        layoutModel.doAction(Actions.maximizeToggle(workbenchTabSetId));
        setLayoutModel(layoutModel);
        aiDisplayedRef.current = aiAssistantDisplayed;
      }
    }
  }, [aiAssistantDisplayed]);

  const popIntoModal = () => {
    layoutModel && layoutModel.doAction(Actions.maximizeToggle(workbenchTabSetId));
    setLayoutModel(layoutModel);
    setDisplayModal(true);
  };

  const addBack = () => {
    layoutModel && layoutModel.doAction(Actions.maximizeToggle(workbenchTabSetId));
    setLayoutModel(layoutModel);
  };

  const factory = (node: TabNode) => {
    let component = node.getComponent();
    if (component === assistantTabId) {
      return (
        <AiAssistant
          ref={assistantRef}
          historyShown={historyShown}
          openHistoryCallback={(grow) => {
            setHistoryShown(grow);
            const currentAssistantWidth = assistantRef.current?.getBoundingClientRect().width || 0;
            const newWidth = grow ? currentAssistantWidth + 250 : currentAssistantWidth - 250;
            const availableWidth = (layoutModel && layoutModel.getRoot().getRect().width) || 0;
            const seeqWidth = availableWidth - newWidth;

            layoutModel &&
              layoutModel.doAction(
                Actions.adjustSplit({
                  node1Id: workbenchTabSetId,
                  weight1: seeqWidth,
                  pixelWidth1: seeqWidth,
                  node2Id: assistantTabSetId,
                  weight2: newWidth,
                  pixelWidth2: newWidth,
                }),
              );
            setLayoutModel(layoutModel);
          }}
        />
      );
    } else {
      return children;
    }
  };

  const onRenderTabSet = (node: TabSetNode | BorderNode, renderValues: ITabSetRenderValues) => {
    if (node instanceof TabSetNode && node.getId() === assistantTabSetId) {
      renderValues.headerContent = (
        <div>
          <Icon icon="fa-sparkles" iconPrefix="fa-solid" type="text" extraClassNames="mr5" />
          AI Assistant
        </div>
      );

      renderValues.headerButtons.push(
        <div
          key={'popOut'}
          onMouseDown={() => {
            popIntoModal();
            doTrack('AiAssistant', 'pop Ai Assistant into panel');
          }}>
          <Icon
            icon="fa-arrow-up-right-from-square"
            extraClassNames="cursorPointer mr10"
            tooltip={t('AI_ASSISTANT.POP_OUT')}
          />
        </div>,
      );
    }
  };

  return (
    <>
      {(headlessRenderMode() || isPresentationMode || aiStandalone) && <>{children}</>}
      {layoutModel && !headlessRenderMode() && !isPresentationMode && !aiStandalone && (
        <Layout
          icons={{
            closeTabset: (
              <div
                onMouseDown={() => {
                  setAIAssistantDisplayed(false);
                  doTrack('AiAssistant', 'Close Ai Assistant panel from dock');
                }}>
                <Icon icon="fa fa-close" large={true} tooltip={t('CLOSE')} />
              </div>
            ),
            maximize: (
              <div
                onMouseDown={() => {
                  doTrack('AiAssistant', 'toggle AI Assistant panel to fullscreen');
                  setAIAssistantMaximized(true);
                }}>
                <Icon icon="fa-arrow-up-right-and-arrow-down-left-from-center" tooltip={t('AI_ASSISTANT.MAXIMIZE')} />
              </div>
            ),
            restore: (
              <div
                onMouseDown={() => {
                  doTrack('AiAssistant', 'minimize AI Assistant panel');
                  setAIAssistantMaximized(false);
                }}>
                <Icon icon="fa-arrow-down-left-and-arrow-up-right-to-center" tooltip={t('AI_ASSISTANT.RESTORE')} />
              </div>
            ),
          }}
          i18nMapper={(id: I18nLabel) => {
            if (id === 'Move tabset') {
              return t('AI_ASSISTANT.CLICK_AND_DRAG');
            } else if (id === 'Close tabset') {
              return '';
            }
            return id;
          }}
          ref={layoutRef}
          model={layoutModel}
          onRenderTabSet={onRenderTabSet}
          factory={factory}
          realtimeResize={true}
        />
      )}
      {displayModal && (
        <Rnd
          className="aiAssistantModal aiModalBackground"
          props={{
            historyShown,
          }}
          default={{
            x: 200,
            y: 50,
            width: historyShown ? 700 : 450,
            height: 550,
          }}
          minWidth={historyShown ? 700 : 450}
          minHeight={500}
          bounds="body">
          <div className="flexRowContainer height-maximum">
            <div className="p5 flexColumnContainer flexAlignCenter">
              <div className="flexFill aiToolbarTitle ml5">
                <Icon icon="fa-sparkles" iconPrefix="fa-solid" type="text" extraClassNames="mr5" />
                AI Assistant
              </div>
              <Icon
                tooltip={t('AI_ASSISTANT.DOCK')}
                icon={'fa-border-top-left'}
                extraClassNames="cursorPointer mr10"
                onClick={() => {
                  addBack();
                  setDisplayModal(false);
                }}
              />
              <Icon
                tooltip={t('CLOSE')}
                icon={'fa-close'}
                extraClassNames="cursorPointer mr5"
                onClick={() => {
                  setAIAssistantDisplayed(false);
                }}
              />
            </div>
            <div className="aiModalContentBackground flexRowContainer flexFill">
              <AiAssistant
                openHistoryCallback={(grow) => {
                  setHistoryShown(grow);
                }}
                historyShown={historyShown}
              />
            </div>
          </div>
        </Rnd>
      )}
    </>
  );
};
